import axios from 'axios';
import * as Sentry from "@sentry/react";

const loadBaseUrl = () => {
    let configStorage: any = sessionStorage.getItem('config');
    if (configStorage) {
        configStorage = JSON.parse(configStorage);
        axios.defaults.baseURL = configStorage.apiEndPoint;
    }

    const token = new URLSearchParams(document.location.search).get('token');
    const clientName = new URLSearchParams(document.location.search).get('client');
    if (token && clientName)
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}`, 'client': clientName, 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS' }
}

axios.interceptors.request.use(
        (config: any) => {
            let requestMessage = `Url: ${config.url}`;
            if (config.data) {
                requestMessage += `, Data: ${JSON.stringify(config.data)}`;
                Sentry.setExtra('Payload', config.data);
                Sentry.captureMessage(`Request ${config.method} - ${requestMessage}`);
            }
            return config;
        },
        (error: any) => {
            return error;
        })

axios.interceptors.response.use(
    (res: any) => {
        let requestMessage = `Url: ${res.config.url}`;
        Sentry.setContext('Response', res);
        res.config.data && Sentry.setExtra('Payload', JSON.parse(res.config.data));
        Sentry.captureMessage(`Response ${res.config.method} - ${requestMessage}`);
        return res;
    },
    async (err: any) => {
        err.respones ? Sentry.setContext('Response', err.response) : Sentry.setContext('Response', err.config);
        err.config?.data && Sentry.setExtra('Payload', JSON.parse(err.config.data));
        const fetchError = new Error();
        fetchError.name = err.message;
        fetchError.message = err.request;
        Sentry.captureException(fetchError);

        const originalConfig = err.config;
        if (err.message === 'Network Error' && !originalConfig._retry) {
            Sentry.captureMessage(`Network Retry - ${JSON.stringify(err.config)}`);
            originalConfig._retry = true;
            return setTimeout(async() => { 
                await axios(originalConfig);
            }, 1000);
        }
        return err;
    }
);

const http = {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    patch: axios.patch,
    loadBaseUrl: loadBaseUrl
};

export default http;