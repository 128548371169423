import { BrowserRouter } from "react-router-dom";
import AppLayout from './component/appLayout';

function App() {
  return (
    <BrowserRouter>
      <AppLayout />
    </BrowserRouter>
  );
}

export default App;
