import { useEffect, useState } from 'react';
import { SelectField, ButtonField } from '@wk/components-react16';
import surveyService from '../service/surveyService';
import '@wk/components-react16/dist/button-field.min.css';
import '@wk/components-react16/dist/forms.min.css';
import './css/survey.css';

const ClaimCredits = (props: any) => {
    const [selectedCredits, setSelectedCredits] = useState<any>();
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [description, setDescription] = useState<string>();
    const [credits, setCredits] = useState<any>();
    const [isLoading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const creditGiven = props.data.creditGiven;
        const creditIncrement = props.data.creditIncrement || 0.25;

        const message = `Up to ${creditGiven} Credits in ${creditIncrement} increments`
        setDescription(message);

        let creditsArr = [];
        let creditOptions = creditIncrement
        do {
            creditsArr.push(creditOptions);
            creditOptions += creditIncrement;
        }
        while (creditOptions <= creditGiven);
        setCredits(creditsArr);
        setSelectedCredits(creditsArr[creditsArr.length - 1]);
    }, [props])

    const handleChange = (event: any) => {
        setSelectedCredits(event.target.value);
    }

    const saveCredits = async () => {
        if (props.params) {
            setLoading(!isLoading);
            const reqObj = {
                clientId: props.params.clientId,
                specialty: props.params.specialty,
                volume: props.params.volume,
                issue: props.params.issue,
                lecture: props.params.lecture,
                userId: props.params.userId,
                creditSelected: selectedCredits,
                memberIdentifier: props.params.ssoId || ''
            }
            const result = await surveyService.saveClaimedCredits(reqObj);
            if (result) {
                setIsDisabled(true);
                setLoading(!isLoading)
                props.handleCertificate();
            } else {
                setLoading(!isLoading);
            }
        }
    }

    return (
        <>
            {
                <div className='claimedCreditContainer'>
                    <div>
                        <SelectField
                            label="CLAIM CREDIT FOR THIS PROGRAM:"
                            labelFor="select-id-Credits"
                            description={description}>
                            <select id="select-id-Credits" name="claimCredits" value={selectedCredits} onChange={handleChange}>
                                {
                                    credits && credits.map((credit: any, index: any) => <option key={index} id={index} value={credit}>{credit}</option>)
                                }
                            </select>
                        </SelectField>
                    </div>
                    <div className='button-position-right'>
                        <ButtonField>
                            <button type="button" disabled={isDisabled} onClick={saveCredits}>
                                Claim Credits
                            </button>
                        </ButtonField>
                    </div>
                </div>
            }
            {
                isLoading && <div className="loader" />
            }
        </>
    );

}

export default ClaimCredits;