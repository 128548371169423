import { useState, useEffect, useCallback } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { StylesManager, Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import surveyService from '../service/surveyService';
import './css/survey.css';

StylesManager.applyTheme("defaultV2");

const SurveyForm = (props: any) => {
    const navigate = useNavigate();
    const params = useParams();
    const clientId = params.clientId;
    const search = useLocation().search;
    const testSource = new URLSearchParams(search).get('testsource');
    const opt = new URLSearchParams(search).get('opt');
    const userId = new URLSearchParams(search).get('uid');
    const productName = new URLSearchParams(search).get('pn');
    const sessionIdentifier = new URLSearchParams(search).get('si');
    const specialty = new URLSearchParams(search).get('sp');
    const volume = new URLSearchParams(search).get('vo');
    const issue = new URLSearchParams(search).get('is');
    const lecture = new URLSearchParams(search).get('lec');
    const conferenceId = new URLSearchParams(search).get('conf');
    const creditsAwarded = new URLSearchParams(search).get('ca');
    const societyAcronym = new URLSearchParams(search).get('sa');
    const ssoId = new URLSearchParams(search).get('mi');
    const correctStr = "xx_Correct_xx";
    const inCorrectStr = "xx_Incorrect_xx";

    const [surveyData, setSurveyData] = useState<any>();

    const removeAllItems = () => {
        if (sessionStorage.getItem('ats'))
            sessionStorage.removeItem('ats')
        if (sessionStorage.getItem('eval'))
            sessionStorage.removeItem('eval')
        if (sessionStorage.getItem('evalLoaded'))
            sessionStorage.removeItem('evalLoaded')
    }

    useEffect(() => {
        removeAllItems();
        //get json file data
        (async () => {
            if (clientId && userId && sessionIdentifier && props.config) {
                switch (testSource) {
                    case '1':
                        //get data for ATS
                        if (specialty && volume && issue && lecture && productName) {
                            await loadATSForm(+clientId, userId, sessionIdentifier, productName, specialty, volume, issue, lecture, '');
                        }
                        break;
                    case '2':
                        //get data for evaluation
                        if (conferenceId) {
                            await loadEvalForm(+clientId, +conferenceId);
                        }
                        break;
                    case '3':
                        //get data for both
                        if (specialty && volume && issue && lecture && conferenceId && productName && opt) {
                            sessionStorage.setItem('evalLoaded', "false");
                            await loadATSForm(+clientId, userId, sessionIdentifier, productName, specialty, volume, issue, lecture, conferenceId);
                        }
                        break;
                    default:
                        break;
                }
            }
        })();
    }, [props.config, clientId, conferenceId, issue, lecture, opt, productName, sessionIdentifier, specialty, testSource, userId, volume]) // eslint-disable-line react-hooks/exhaustive-deps

    const loadATSForm = async (clientId: number, userId: string, sessionIdentifier: string, productName: string, specialty: string, volume: string, issue: string, lecture: string, conferenceId: string) => {
        const atsData = await surveyService.getATSSurveyData(clientId, userId, sessionIdentifier, productName, specialty, volume, issue, lecture);
        if (atsData && atsData.isPassed) {
            if (testSource === '1') {
                const path = `/survey/thankyou`;
                navigate(path, { state: { clientId: clientId, userId: userId, specialty: specialty, volume: volume, issue: issue, lecture: lecture } });
            } else {
                sessionStorage.setItem('evalLoaded', "true");
                await loadEvalForm(+clientId, +conferenceId);
            }

        } else {
            loadData(atsData, "ats");
        }
    }

    const loadEvalForm = async (clientId: number, conferenceId: number) => {
        const evalData = await surveyService.getEvalSurveyData(clientId, conferenceId);
        loadData(evalData, "eval");
    }

    const loadData = (data: any, storageName: string) => {
        let questionAnswers = [];
        const elements = data.pages ? data.pages[0].elements : data.elements;

        for (let element of elements) {
            if(storageName === 'ats'){
                 element.isRequired = true;
            }
            questionAnswers.push(element);
        }

        data.elements = questionAnswers;
        data.showCompletedPage = true;
        data.completeText = "Submit";
        data.completedHtml = "<div class='loader'/>";

        if (storageName === 'ats') {
            data.description = `You must score ${data.passingGrade}% on the post-test to receive credit. You will have an opportunity to re-take any questions you missed until you score ${data.passingGrade}%. After you have received this passing score, a link will be provided that allows you to generate and print your CME certificate.`;
        }

        setSurveyData(data);
        sessionStorage.setItem(storageName, JSON.stringify(data));
    }

    const survey = new Model(surveyData);

    const surveyComplete = useCallback(async (sender: any) => {
        if (surveyData) {
            let surveyJson: any = surveyData;

            let questionAnswers = [];
            for (let val of Object.entries(sender.data)) {
                const element = surveyJson.elements.filter((x: { name: string; }) => x.name === val[0])[0];
                element.answer = '' + val[1];
                questionAnswers.push(element);
            }
            surveyJson.elements = questionAnswers;

            switch (testSource) {
                case '1':
                    let ansSequence = "";
                    for (let val of Object.values(sender.data)) {
                        ansSequence += val;
                    }

                    const atsRequestObj: any = { clientId: clientId, title: surveyJson.title, userId: userId, productName: productName, sessionIdentifier: sessionIdentifier, specialty: specialty, volume: volume, issue: issue, lecture: lecture, creditGiven: surveyJson.creditGiven, editCreditAllowed: surveyJson.editCreditAllowed, passingGrade: surveyJson.passingGrade, answerSequence: ansSequence, responses: questionAnswers, shouldAddScoreToATS: true, memberIdentifier: ssoId || null };
                    const atsResult = await surveyService.checkAnswers(atsRequestObj);
                    if (atsResult && atsResult.isPassed) {
                        sessionStorage.setItem('isCertificateProcessed', atsResult.isCertificateProcessed);
                        navigateToThanks();
                    } else {
                        if (atsResult.answerKeys) {
                            validateAnswers(atsResult, sender);
                        }
                    };
                    break;

                case '2':
                    const evalRequestObj = { clientId: clientId, title: surveyJson.title, userId: userId, productName: productName, sessionIdentifier: sessionIdentifier, response: JSON.stringify(surveyJson), specialty: specialty, volume: volume, issue: issue, lecture: lecture, creditsAwarded: creditsAwarded, shouldAddScoreToATS: true, memberIdentifier: ssoId || null };
                    const evalResult = await surveyService.saveSurveyResult(evalRequestObj);
                    if (evalResult) {
                        sessionStorage.setItem('isCertificateProcessed', evalResult.isCertificateProcessed);
                        navigateToThanks();
                    }
                    break;

                case '3':
                    if (sessionStorage.getItem('evalLoaded') === "false") {
                        let ansSequence = "";
                        for (let val of Object.values(sender.data)) {
                            ansSequence += val;
                        }

                        const responseObj = { clientId: clientId, title: surveyJson.title, userId: userId, productName: productName, sessionIdentifier: sessionIdentifier, specialty: specialty, volume: volume, issue: issue, lecture: lecture, creditGiven: surveyJson.creditGiven, editCreditAllowed: surveyJson.editCreditAllowed, passingGrade: surveyJson.passingGrade, answerSequence: ansSequence, responses: questionAnswers, shouldAddScoreToATS: opt === "1", memberIdentifier: ssoId || null };
                        const result = await surveyService.checkAnswers(responseObj);
                        if (result && result.isPassed) {
                            if (conferenceId && clientId) {
                                sessionStorage.setItem('isCertificateProcessed', result.isCertificateProcessed);
                                sessionStorage.setItem('evalLoaded', "true");
                                await loadEvalForm(+clientId, +conferenceId);
                            }
                        } else {
                            if (result.answerKeys) {
                                validateAnswers(result, sender);
                            }
                        }
                    }
                    else {
                        const responseObj = { clientId: clientId, title: surveyJson.title, userId: userId, productName: productName, sessionIdentifier: sessionIdentifier, response: JSON.stringify(surveyJson), specialty: specialty, volume: volume, issue: issue, lecture: lecture, creditsAwarded: creditsAwarded, shouldAddScoreToATS: true, memberIdentifier: ssoId || null };
                        const result = await surveyService.saveSurveyResult(responseObj);
                        if (result) {
                            sessionStorage.setItem('isCertificateProcessed', result.isCertificateProcessed);
                            sessionStorage.setItem('evalLoaded', "false");
                            navigateToThanks();
                        }
                    }
                    break;

                default:
                    break;
            }
        }
    }, [surveyData, clientId, conferenceId, creditsAwarded, issue, lecture, productName, sessionIdentifier, specialty, testSource, userId, volume]); // eslint-disable-line react-hooks/exhaustive-deps

    const validateAnswers = (result: any, sender: any) => {
        const answerResults = result.answerKeys.split('');
        let correctedAnswers = [];
        for (let i = 0; i < Object.entries(sender.data).length; i++) {
            if (answerResults[i] === 'T') {
                correctedAnswers.push(Object.entries(sender.data)[i]);
            }
            survey.getAllQuestions().forEach((a: any) => {
                let inputString = a.choices[a.choices.length - 1].locTextValue.renderedText;
                if (a.name === Object.entries(sender.data)[i][0] && answerResults[i] === 'T') {
                    if (inputString.includes(inCorrectStr)) {
                        a.choices[a.choices.length - 1].locTextValue.values.default = inputString.replace(inCorrectStr, correctStr);
                    }
                    else if (!inputString.includes(correctStr)){
                        a.choices[a.choices.length - 1].locTextValue.values.default = inputString+ ' ' + correctStr;
                    }
                }
                else if (a.name === Object.entries(sender.data)[i][0] && answerResults[i] === 'F') {
                    if (inputString.includes(correctStr)) {
                        a.choices[a.choices.length - 1].locTextValue.values.default = inputString.replace(correctStr, inCorrectStr);
                    }
                    else if (!inputString.includes(inCorrectStr)){
                        a.choices[a.choices.length - 1].locTextValue.values.default = inputString+ ' ' + inCorrectStr;
                    }
                }
            });
        }

        survey.clear(true, true);
        survey.data = Object.fromEntries(correctedAnswers);;
        survey.render();
    }

    const getTextHtml = (text: any, str: any) => {
        if (text.indexOf(str) < 0) return undefined;
        if (str === correctStr)
            return text.substring(0, text.indexOf(str)) + "<br/><br/><span style='color:green;font-weight:600'><i class='wk-icon-check' style='font-weight:600'></i>Correct</span>";
        else if (str === inCorrectStr)
            return text.substring(0, text.indexOf(str)) + "<br/><br/><span style='color:red;font-weight:600'><i class='wk-icon-close' style='font-weight:600'></i>Incorrect</span>";
    }

    survey.onTextMarkdown.add((sender, options) => {
        let text = options.text;
        let html = getTextHtml(text, correctStr);
        if (!html) {
            html = getTextHtml(text, inCorrectStr);
        }
        if (!!html) {
            options.html = html;
        }
    });

    survey.onComplete.add(surveyComplete);

    const handleSkipEvaluation = () => {
        navigateToThanks();
    }

    const navigateToThanks = () => {
        let isCertificateProcessed = sessionStorage.getItem('isCertificateProcessed');
        sessionStorage.removeItem('isCertificateProcessed');
        const path = `/survey/thankyou`;
        navigate(path, { state: { clientId: clientId, userId: userId, specialty: specialty, volume: volume, issue: issue, lecture: lecture, societyAcronym: societyAcronym, hasCertificate: isCertificateProcessed, ssoId: ssoId } });
    }

    return (
        <>
            {sessionStorage.getItem('evalLoaded') === "true" && opt === "1" &&
                <button className='skip-eval' onClick={handleSkipEvaluation}><h3>Skip</h3></button>
            }
            {surveyData ? <Survey model={survey} /> : <div className="loader"></div>}
        </>
    );
}

export default SurveyForm;