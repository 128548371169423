import { Notification } from '@wk/components-react16';
import '@wk/components-react16/dist/fonts.css';
import '@wk/components-react16/dist/notification.css';

const Error = (props: any) => {
    return (
        <div className="outer">
            <div className="middle">
                <div className="inner">
                    <Notification type="error" heading="Error" hasCloseButton={false}>
                        <span slot="notificationContent">
                            Something went wrong. Please try again!
                        </span>
                    </Notification>
                </div>
            </div>
        </div>)
}

export default Error;