import { useState, useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import SurveyForm from "./surveyForm";
import SurveyComplete from './surveyComplete';
import Error from './error';
import http from '../service/httpService';
import * as Sentry from "@sentry/react";

const AppLayout = () => {

    const [config, setConfig] = useState();

    const getConfig = async () => {
        try {
            const baseURL = window.location.origin;
            const result = await http.get(baseURL + '/js/config.json');
            return result.data;
        }
        catch (e) {
            console.log(e)
        }
    }

    //sentry configuration
    const setupSentry = (sentryConfig: any) => {
        Sentry.init({
            dsn: sentryConfig.dsn,
            tracesSampleRate: sentryConfig.tracesSampleRate,
            environment: sentryConfig.environment,
            release: sentryConfig.release,
            initialScope: {
                tags: { "App": sentryConfig.tags }
            },
            beforeSend(event) {
                if (sentryConfig.logLevel.includes(event.level))
                    return event;

                return null;
            }
        });
        const token = new URLSearchParams(document.location.search).get('token');
        if (token) {
            const data = JSON.parse(atob(token.split('.')[1]));
            Sentry.setUser({ id: data.name });
        }
    }

    useEffect(() => {
        (async () => {
            const configData = await getConfig();
            setupSentry(configData.sentry);
            setConfig(configData);
            sessionStorage.setItem('config', JSON.stringify(configData));
            http.loadBaseUrl();
        })();
    }, []);

    return (
        <div>
            {config ?
                <Routes>
                    <Route path="survey/:clientId" element={<SurveyForm config={config} />} />
                    <Route path="survey/thankyou" element={<SurveyComplete config={config} />} />
                    <Route path="*" element={<Error />} />
                </Routes> :
                <div className="loader"></div>
            }
        </div>
    )
}

export default Sentry.withProfiler(AppLayout);