import ClaimCredits from "./claimCredits";
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ButtonField } from '@wk/components-react16';
import '@wk/components-react16/dist/button-field.min.css';

const SurveyComplete = (props: any) => {
    const location = useLocation();
    const [editCreditAllowed, setEditCreditAllowed] = useState(false);
    const [surveyJson, setSurveyJson] = useState<any>();

    useEffect(() => {
        let data: any = sessionStorage.getItem('ats');
        if (data) {
            data = JSON.parse(data);
            setSurveyJson(data);
            setEditCreditAllowed(data.editCreditAllowed);
        }
    }, []);

    const handleCertificate = () => {
        setEditCreditAllowed(false);
    }

    const showCertificate = () => {
        const url = `${props.config.assetsUrl}/SecureResources/BoldReports/${location.state.societyAcronym}/${location.state.userId}_${location.state.societyAcronym}_${location.state.volume}.pdf`;
        window.open(url, "_blank");
    }

    return (
        <>
            {
                <div className="outer">
                    <div className="middle">
                        <div className="inner">
                            <div style={{ textAlign: 'center', marginTop: '5%' }}>
                                {
                                    surveyJson && surveyJson.title ?
                                        <h3>Congratulations. You have successfully completed the <i>"{surveyJson.title}"</i> evaluation.</h3> :
                                        <h3>Thank you for completing the survey.</h3>
                                }
                            </div>
                            {
                                editCreditAllowed && <ClaimCredits data={surveyJson} params={location.state} handleCertificate={handleCertificate} />
                            }
                            {
                                (!editCreditAllowed && props.config) &&
                                    <div style={{ textAlign: 'center', marginTop: '2rem' }}>
                                        {location.state.hasCertificate === 'true' ?
                                        <ButtonField>
                                            <button type="button" onClick={showCertificate}>
                                                View Certificate
                                            </button>
                                        </ButtonField>
                                        : <h5>Certificate will be processed momentarily</h5>}
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    );

}

export default SurveyComplete;