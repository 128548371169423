import http from './httpService';

class SurveyService {
    public async getATSSurveyData(clientId: number, userId: string, sessionIdentifier: string, productName: string, specialty: string, volume: string, issue: string, lecture: string) {
        try {
            const atsObj = { clientId: clientId, userId: userId, sessionIdentifier: sessionIdentifier, productName: productName, specialty: specialty, volume: volume, issue: issue, lecture: lecture };
            const result = await http.post(`/api/Evaluation/get-test-questions`, atsObj);
            return result.data;
        } catch (ex) {
            console.log(ex);
        }
    }

    public async getEvalSurveyData(clientId: number, conferenceId: number) {
        try {
            const evalObj = { clientId: clientId, conferenceId: conferenceId };
            const result = await http.post(`/api/Evaluation/get-evalation-questions`, evalObj);
            return result.data;
        } catch (ex) {
            console.log(ex);
        }
    }

    public async checkAnswers(response: any) {
        try {
            const result = await http.post(`/api/Evaluation/check-answers`, response);
            return result.data;
        } catch (ex) {
            console.log(ex);
        }
    }

    public async saveSurveyResult(response: any) {
        try {
            const result = await http.post(`/api/Evaluation/process-evaluations`, response);
            return result.data;
        } catch (ex) {
            console.log(ex);
        }
    }

    public async saveClaimedCredits(response: any) {
        try {
            const result = await http.post(`/api/Evaluation/customer-credit-selected`, response);
            return result.data;
        } catch (ex) {
            console.log(ex);
        }
    }
}

let surveyService = new SurveyService();
export default surveyService;